import React from 'react';
import { NavLink } from 'react-router-dom';

export const AdminNav = () => {
  return (
    <header className="py-3">
      <div className="container">
        <nav className="navbar navbar-expand-xl navbar-light p-0">
          <NavLink className="navbar-brand" to="#">
            <img src="../../images/logo.png" alt="logo-img" />
          </NavLink>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className="nav-link" to="/seller-listing" activeClassName="active">Sellers <span className="sr-only">(current)</span></NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/dealer-listing" activeClassName="active">Dealers</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/agent-list" activeClassName="active">Agents</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/live-car-auctions" activeClassName="active">Live in Auctions</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/future-car-auctions" activeClassName="active">Upcoming Cars</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/sold-cars" activeClassName="active">Sold Cars</NavLink>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Monthly Analytics 
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <NavLink className="dropdown-item" to="/total-number-car-available" activeClassName="active">Total number of cars Available</NavLink>
                  <NavLink className="dropdown-item" to="/total-number-car-sold" activeClassName="active">Total number of cars Sold</NavLink>
                  <NavLink className="dropdown-item" to="/total-number-seller" activeClassName="active">Total number of Sellers</NavLink>
                  <NavLink className="dropdown-item" to="/average-calculation-bid" activeClassName="active">Average calculation of winning bids</NavLink>
                  <NavLink className="dropdown-item" to="/highest-winning-bid" activeClassName="active">Highest winning Bid</NavLink>
                  <NavLink className="dropdown-item" to="/Cars-sold-per-day-of-the-month" activeClassName="active">Cars sold per day of the month</NavLink>
                  <NavLink className="dropdown-item" to="/bids-per-day-of-the-month" activeClassName="active">Bids per day of the month</NavLink>
                  <NavLink className="dropdown-item" to="/new-cars-listed-per-month" activeClassName="active">New cars listed per month</NavLink>
                </div>
              </li>
            </ul>
            <div className="signout-btn">
              <NavLink to="/" className="btn btn-primary">Sign Out</NavLink>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};
