import React, { useState, useEffect } from 'react';
import { AdminNav } from '../NavBar/AdminNav';
import api from '../api';
import { useNavigate } from 'react-router-dom';
export const  LiveCarAuctions = () => {
const navigate = useNavigate();
const [posts, setPosts] = useState([]);
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true); // Add a loading state
const storedDealerId = sessionStorage.getItem('user_id');
const [searchQuery, setSearchQuery] = useState('');
const [minutesMap, setMinutesMap] = useState({});
useEffect(() => {
const fetchPosts = async () => {
try {
const response = await api.get(`/vehiclelisting/0/Admin/LiveInAuction`);
const { message, Vehicle } = response.data;
// Check if message is true and Vehicle is an array
if (message && Array.isArray(Vehicle)) {
// setVehicleId();
// Set the Vehicle data in state
setPosts(Vehicle);
setLoading(false); 
Vehicle.forEach((vehicle) => {
	fetchAuctionTimeout(vehicle.Vehicle_Id);
  });
} else {
throw new Error('Invalid data format: Vehicle array not found');
}
} catch (error) {
setError(error.message);
setLoading(false); 
}
};
fetchPosts();
}, [navigate, storedDealerId]);
const fetchSerchdata = async () => {
try {
const response = await api.post(`/vehiclesearch/0/Admin/SoldCars`, {
search: searchQuery
});
// Extract the array of vehicles from the "Vehicle" property
const vehicles = response.data.Vehicle;
// Set the state with the array of vehicles
setPosts(vehicles);
} catch (error) {
console.error('Error fetching data:', error);
}
};
const handleSearchChange = (event) => {
setSearchQuery(event.target.value);
fetchSerchdata();
};
useEffect(() => {
	fetchAuctionTimeout();
  }, []);
  const fetchAuctionTimeout = async (vehicleId) => {
	try {
	  const response = await api.get(`/timeoutdetails/${vehicleId}`);
	  console.log(`API Response for Vehicle ${vehicleId}:`, response.data);
  
	  setMinutesMap((prevState) => ({
		...prevState,
		[vehicleId]: response.data.counterminutes, // Store minutes for this vehicle
	  }));
	} catch (error) {
	  console.error(`Error fetching auction timeout for Vehicle ${vehicleId}:`, error);
	}
  };
return (
<section className="car-details">
	<AdminNav />
	<div className="container">
		<div className="view-post-panel mid-table mt-4">
			<h3 className="main-heading py-3">No Auctions in Cars</h3>
			<div className="car-bid-gallary">
				<form onSubmit={(e) => e.preventDefault()} className="input-group">
					<button type="submit"><i className="fa-solid fa-magnifying-glass"></i></button>
					<input
						type="search"
						id="form1"
						className="form-control"
						placeholder="Search Cars"
						value={searchQuery}
						onChange={handleSearchChange}
						/>
				</form>
				
            <div className="carbid-gallery-panel py-4">
                <div className="row">
                  {loading ? (
                    <p>Loading...</p> // Render loading state while fetching data
                  ) : posts.length === 0 ? (
                    <div className="col-md-12">
                      <p className="text-center text-danger">No Car in Live Auction</p>
                    </div>
                  ) : (
                    posts.map((post) => (
                      <div key={post.Vehicle_Id} className="col-lg-4 col-12">
                        <a href={`/live-auction-details/${post.Vehicle_Id}`}>
                          <div className="carbid-image-panel">
                            {post.Exterior_Image && (
                              <img
                                src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image}`}
                                alt="Car"
                              />
                            )}
                            {post.Exterior_Image2 && !post.Exterior_Image && (
                              <img
                                src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image2}`}
                                alt="Car"
                              />
                            )}
                            {post.Exterior_Image3 &&
                              !post.Exterior_Image2 &&
                              !post.Exterior_Image && (
                                <img
                                  src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image3}`}
                                  alt="Car"
                                />
                              )}
                            {post.Exterior_Image4 &&
                              !post.Exterior_Image3 &&
                              !post.Exterior_Image2 &&
                              !post.Exterior_Image && (
                                <img
                                  src={`https://backend.carchaser.ca/uploads/${post.Vehicle_Id}/${post.Exterior_Image4}`}
                                  alt="Car"
                                />
                              )}
                            <div className="bidpanel-innercont">
                              <h6 className="pt-2">
                                {post.Year} {post.Make} {post.Model}{" "}
                              </h6>
                              <span>
                                <small>Vin #{post.VIN}</small>
                              </span>

                              {/* Display the auction timeout for this vehicle */}
                              <p className="text-white">
                                Auction TimeLeft:{" "}
                                {minutesMap[post.Vehicle_Id] !== undefined
                                  ? `${minutesMap[post.Vehicle_Id]} minutes`
                                  : "Loading..."}
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))
                  )}
                </div>
            </div>
         </div>
			</div>
		</div>
</section>
);
};